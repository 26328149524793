import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Tools } from '../_shared/tools';
import { Product } from '../_models/product';
import { ProductContent } from '../_models/product-content';
import { Globals } from '../globals';
import ProductSalesPopUpData from '../_models/product-sales-popup-data';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ProductService {
  public cache = {
    category: -1,
    products: [] as Product[],
  };

  constructor(private http: HttpClient, public auth: AuthService, public tools: Tools, public globals: Globals) {}

  public getProductById(id: number): Promise<Product> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const vendorId = localStorage.getItem('vendor');
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, 'vendor', vendorId, environment.apiEndpoints.products, id]);
    return this.http
      .get(requestUrl, options)
      .toPromise()
      .then((res) => res as Product);
  }

  public getProductContents(id: number): Promise<ProductContent[]> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.products, id, 'contents']);
    return this.http
      .get(requestUrl, options)
      .toPromise()
      .then((res) => res as ProductContent[]);
  }

  public getProductSalesPopUpData(): Observable<ProductSalesPopUpData[]> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.products, 'top-selling']);
    return this.http.get<ProductSalesPopUpData[]>(requestUrl, options);
  }

  public getProducts(category: number = 0, country: string = ''): Observable<Product[]> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    let vendorId = this.globals.Store.VendorId;
    if (!vendorId) {
      vendorId = localStorage.getItem('vendor');
      if (!vendorId) {
        return throwError('Vendor info not loaded correctly');
      }
    }
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, 'vendor', vendorId, environment.apiEndpoints.products]);
    console.log(requestUrl);
    return this.http.get<Product[]>(requestUrl + '?c=' + category.toString() + '&cc=' + country, options);
  }
}
