import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customer } from './../_models/customer';
import { AuthService } from './../_services/auth.service';
import { environment } from '../../environments/environment';
import { Tools } from '../_shared/tools';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient, private auth: AuthService, public globals: Globals, public tools: Tools) {}

  getCustomer(id: string): Promise<Customer> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.customers, id]);
    return this.http
      .get(requestUrl, options)
      .toPromise()
      .then((res) => res as Customer);
  }

  newCustomer(vendorId: string): Observable<Customer> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.customers]);
    return this.http.post<Customer>(requestUrl, { VendorId: vendorId, Origin: 'LIFEPH' }, options).pipe(
      map((customer) => {
        customer.Cart.OtherCharges = [];
        customer.Cart.PaymentMethod = 0;
        return customer;
      }),
    );
  }

  getVendor(name: string): Promise<any> {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.auth.token });
    const options = { headers: header };
    const requestUrl = this.tools.buildUrl([environment.shopApiUrl, environment.apiEndpoints.vendor, name]);
    return this.http
      .get(requestUrl, options)
      .toPromise()
      .then((res) => res as string);
  }
}
