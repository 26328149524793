import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.css']
})
export class OrderCompletedComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  OrderId: number;
  Payment: string;
  ebankUrl: string;
  EncOID: string;

  ngOnInit(): void {
    this.ebankUrl = environment.ebankUrl;
    this.activatedRoute.paramMap.pipe(switchMap((params: ParamMap) => {
      console.log(params);
      const id = params.get('id');
      this.OrderId = +id;
      this.EncOID = btoa(id);

      const payment = params.get('payment');
      this.Payment = payment;

      return Promise.resolve();
    })).subscribe();
  }
}
