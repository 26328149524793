export const environment = {
  production: false,
  baseUrl: 'http://dev.onelifestyle.shop',
  shopApiUrl: 'https://testapi.unomain.com/onelifestyle/api',
  authUrl: 'https://testidp.unomain.com/oauth/token',
  appsUrl: 'https://appstest.unomain.com',
  payUrl: 'https://ptiapps.paynamics.net/webpayment/default.aspx',
  imageUrl: 'https://images.unomain.com/coxpchxeixfgmngjgrvov2/upl/',
  regional2: 'https://testregionalapi.unomain.com',
  ebankUrl: 'https://test-e-bank.unomain.net',
  apiEndpoints: {
    profiles: 'profiles',
    customers: 'customers',
    vendor: 'vendor',
    products: 'products',
    catalog: 'catalog',
    carts: 'carts',
    cartItems: 'cartitems',
    regional: 'regional',
  },
  recaptchaKey: '6LeQl9glAAAAABJiv73Vk7tfQ9Sqydq8haB44W6I',
  paypalClientId: 'AbGJ0mA1xJcHDXp_iUl91MNZtN6Ml79WwL-GrDeeddbnSQh7ae5AYVCd56FOHHAI_S1Azt7tkSy7mh9k',
  creditCardChargePercent: 0.035,
  paypalChargePercent: 0.039,
  GA_TRACKING_ID: 'G-RJNG2PQWCY',
};
// ng build --configuration=staging
