<p class="p-5 text-center mainText">
  Welcome to Onelifestyle <br/>Please navigate to your distributor page to view all available products.
</p>

<div class="vendorContainer">
	<mat-form-field appearance="standard">
    <mat-label>Distributor Name:</mat-label>
    <input matInput [formControl]="vendorName" />
	</mat-form-field>
	<div class="buttonContainer">
		<button type="submit" mat-raised-button color="warn" (click)="changeVendor()">Submit</button>
	</div>
</div>