<div *ngIf="globals.Store.Customer.Id !== ''">
  <nav class="navbar navbar-light bg-light">
    <span class="navbar-brand mb-0 h1">My Shopping Cart</span>
  </nav>
  <div class="row m-0 text-center" *ngIf="globals.isCartEmpty()">
    <div class="p-3" style="margin:0 auto;">
      Your Cart is empty!
      <div class="p-4 text-center">
        <button class="mx-2 w-100" mat-raised-button color="primary" (click)="continue()">Continue Shopping</button>
      </div>
    </div>
  </div>
  <div class="row m-0" *ngIf="!globals.isCartEmpty()">
    <div class="col-md-8">
      <mat-card *ngFor="let item of globals.Store.Customer.Cart.Items" style="position:relative">
        <div class="cart-item row w-100" id="pd{{item.Id}}">
          <div class="col-5 col-md-3">
            <img style="width:120px; height:120px" alt="{{item.Product.Name}}" data-src="{{globals.Store.ProductsImageUrl + 'images/products/noimage.png'}}" *ngIf="!item.Product.ImageUrl" />
            <img style="width:120px; height:120px" alt="{{item.Product.Name}}" data-src="{{item.Product.ImageUrl.length > 50 ? item.Product.ImageUrl : globals.Store.ProductsImageUrl + item.Product.ImageUrl}}" *ngIf="item.Product.ImageUrl" />
          </div>
        <div class="cart-details col-7 col-md-9">
          <div class="row m-0">
            <div class="item col-12 col-md">{{item.Product.Name}}</div>
            <div class="price col-12 col-md-6 col-lg-3" >{{ globals.Store.Currency }} {{item.Product.Price | number:'1.2-2' }}</div>
            <div class="col-12 col-md-12 col-lg-4">
              <div class="qty input-group" style="width:120px">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputQty">Qty</label>
                </div>
                <input class="form-control" type="number" placeholder="--" min="1" [(ngModel)]="item.Quantity" (change)="onQuantityChange(item, $event.target.value)">
              </div>
            </div>
          </div>
          </div> 
        </div>
        <div style="position: absolute; top: 0px; right: 0px;" class="p-3">
          <button type="button" class="close" aria-label="Close" (click)="removeFromCart(item.Id); $event.stopPropagation()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </mat-card>
      <div class="p-3">{{globals.cartItemsQuantity()}} item(s)</div> 
    </div>
    <div class="col-md-4">
      <app-order-summary></app-order-summary>
      <mat-card>
        <div class="text-center">
          <form action="?" method="POST">
          <div class="g-recaptcha" data-sitekey='6LeQl9glAAAAABJiv73Vk7tfQ9Sqydq8haB44W6I'></div>
          <button class="btn-checkout" type="button" mat-raised-button color="warn" (click)="checkout()">PROCEED TO CHECKOUT</button>
          </form>
        </div>
      </mat-card>
    </div>
  </div>
</div>