import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import _ from 'lodash';
import { Globals } from './globals';
import { AuthService } from './_services/auth.service';
import { environment } from '../environments/environment';
import { CustomerService } from './_services/customer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tools } from './_shared/tools';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
declare let fbq: Function;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  pageLoaded = false;
  token: string;
  events: string[] = [];

  constructor(
    public globals: Globals,
    public authService: AuthService,
    public customerService: CustomerService,
    public location: Location,
    public router: Router,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects,
      });
      /** END */
    });
  }

  ngAfterViewInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.swUpdate.activateUpdate().then(() => {
          window.location.reload();
        });
      });
    }
  }

  ngOnInit(): void {
    this.clearStorage();
    console.log('Component initialized...');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (this.globals.isNullOrEmpty(this.globals.Store.Name) || this.globals.Store.Name === 'home') {
        this.pageLoaded = true;
        return;
      }

      if (this.authService.isLogged()) {
        if (this.globals.isNullOrEmpty(this.globals.Store.Customer.Id)) {
          this.getCurrentCustomer();
        }
      } else {
        console.log('Authenticate customer');
        this.authService
          .login()
          .then((res) => {
            if (res === true) {
              this.getCurrentCustomer();
            } else {
              // Cannot authenticate
              this.snackBar.open('Server is currently busy, please try again later.', 'Dismiss', {
                duration: 2000,
              });
              this.pageLoaded = true;
            }
          })
          .catch((error) => {
            this.snackBar.open('Server is currently busy, please try again later.', 'Dismiss', {
              duration: 2000,
            });
            this.pageLoaded = true;
          });
      }
      window.scrollTo(0, 0);
    });
  }

  saveVendorConfig(config: any) {
    console.log('Save vendor config');
    this.globals.Store.VendorConfig.ButtonBackgroundColor = config.ButtonBackgroundColor
      ? config.ButtonBackgroundColor
      : '#f44336';
    this.globals.Store.VendorConfig.ButtonForegroundColor = config.ButtonForegroundColor
      ? config.ButtonForegroundColor
      : '#ffffff';
    this.globals.Store.VendorConfig.EnableSalesPopup = config.EnableSalesPopup ? config.EnableSalesPopup : true;
    this.globals.Store.VendorConfig.EnableSlider = config.EnableSlider ? config.EnableSlider : false;
    this.globals.Store.VendorConfig.FooterText = config.FooterText
      ? config.FooterText
      : '© Onelifestyle 2023. All Rights Reserved';
    this.globals.Store.VendorConfig.HeaderBackgroundColor = config.HeaderBackgroundColor
      ? config.HeaderBackgroundColor
      : '#d9d9d9';
    this.globals.Store.VendorConfig.HeaderForegroundColor = config.HeaderForegroundColor
      ? config.HeaderForegroundColor
      : '#F1F1F1';
    this.globals.Store.VendorConfig.HeaderLogo = config.HeaderLogo ? config.HeaderLogo : 'assets/img/logo.jpg';
    this.globals.Store.VendorConfig.PriceColor = config.PriceColor ? config.PriceColor : '#f57224';
    this.globals.Store.VendorConfig.ProductListFormat = config.ProductListFormat ? config.ProductListFormat : 'grid';
    this.globals.Store.VendorConfig.SlideTimeout = config.SlideTimeout ? config.SlideTimeout : 3000;
    this.globals.Store.VendorConfig.Slides = config.Slides ? config.Slides : [];
    this.globals.Store.VendorConfig.SocialMediaLinks = config.SocialMediaLinks
      ? config.SocialMediaLinks
      : {
          Facebook: '',
          Twitter: '',
          Youtube: '',
          Instagram: '',
        };
    console.log('Save vendor config mid');
    if (config.SocialMediaLinks) {
      this.globals.Store.VendorConfig.SocialMediaLinks.Facebook = config.SocialMediaLinks.Facebook
        ? config.SocialMediaLinks.Facebook
        : '';
      this.globals.Store.VendorConfig.SocialMediaLinks.Youtube = config.SocialMediaLinks.Youtube
        ? config.SocialMediaLinks.Youtube
        : '';
      this.globals.Store.VendorConfig.SocialMediaLinks.Twitter = config.SocialMediaLinks.Twitter
        ? config.SocialMediaLinks.Twitter
        : '';
      this.globals.Store.VendorConfig.SocialMediaLinks.Instagram = config.SocialMediaLinks.Facebook
        ? config.SocialMediaLinks.Instagram
        : '';
    } else {
      this.globals.Store.VendorConfig.SocialMediaLinks.Facebook = '';
      this.globals.Store.VendorConfig.SocialMediaLinks.Youtube = '';
      this.globals.Store.VendorConfig.SocialMediaLinks.Twitter = '';
      this.globals.Store.VendorConfig.SocialMediaLinks.Instagram = '';
    }

    console.log('Save vendor config after');
  }

  clearStorage() {
    localStorage.clear();
  }

  getCurrentCustomer() {
    console.log('Load store info: ' + this.globals.Store.Name);
    this.globals.Store.ProductsImageUrl = environment.imageUrl;
    this.globals.Store.RecaptchaKey = environment.recaptchaKey;
    this.customerService
      .getVendor(this.globals.Store.Name)
      .then((vendor) => {
        if (
          !vendor ||
          this.globals.isNullOrEmpty(vendor) ||
          this.globals.isNullOrEmpty(vendor.Id) ||
          vendor.Id === '0'
        ) {
          this.router.navigate(['home']);
          return;
        }
        if (vendor.AnalyticsId) {
          fbq('init', vendor.AnalyticsId);
        }
        console.log('Vendor: ', vendor);

        this.saveVendorConfig(vendor.Config);
        console.log('After save config');
        this.globals.Store.VendorCountryCode = vendor.CountryCode;
        this.globals.Store.Currency = vendor.Currency || '₱';
        console.log('Currency: ', this.globals.Store.Currency);
        this.globals.Store.VendorId = vendor.Id;
        this.globals.Store.AnalyticsId = vendor.AnalyticsId;
        localStorage.setItem('vendor', vendor.Id);
        console.log('Vendor ID: ', localStorage.getItem('vendor'));
        localStorage.setItem('analytics', vendor.AnalyticsId);
        this.appendPixelBaseCode(vendor.AnalyticsId);
        this.pageLoaded = true;
        const customerId = localStorage.getItem('customer');
        if (!this.globals.isNullOrEmpty(customerId)) {
          this.loadCustomer(customerId);
        } else {
          this.createCustomer();
        }
      })
      .catch((error) => {
        this.snackBar.open('Server is currently busy, please try again later.', 'Dismiss', {
          duration: 2000,
        });
        this.pageLoaded = true;
      });
  }

  loadCustomer(customerId: string) {
    console.log('Load customer info: ' + customerId);
    return this.customerService
      .getCustomer(customerId)
      .then((customer) => {
        this.globals.Store.Customer = customer;
        let cart = this.globals.Store.Customer.Cart;
        cart = customer.Cart;
        cart.UseSameAddress = true;
        cart.ShippingFee = 0.0;
        cart.VendorId = this.globals.Store.VendorId;
        console.log('After loadCustomer');
      })
      .catch((error) => {
        if (error.status) {
          if (error.status === 401) {
            console.log('Re-authenticate, token has expired');
            this.authService.logout();
            this.globals.goToUrl(this.router, 'catalog');
          } else if (error.status === 404) {
            console.log('Customer not exist! create new instead');
            this.createCustomer();
          } else {
            this.snackBar.open('Server is currently busy, please try again later.', 'Dismiss', {
              duration: 2000,
            });
          }
        }
      });
  }

  createCustomer() {
    console.log('Create customer info: ' + this.globals.Store.VendorId);
    return this.customerService.newCustomer(this.globals.Store.VendorId).subscribe({
      next: (customer) => {
        localStorage.setItem('customer', customer.Id);
        this.globals.Store.Customer = customer;
        let cart = this.globals.Store.Customer.Cart;
        cart = customer.Cart;
        cart.UseSameAddress = true;
        cart.ShippingFee = 0.0;
        cart.VendorId = this.globals.Store.VendorId;
        console.log('After createCustomer');
      },
      error: (error) => {
        if (error.status && error.status === 401) {
          console.log('Re-authenticate, token has expired');
          this.authService.logout();
          this.globals.goToUrl(this.router, 'catalog');
        } else {
          this.snackBar.open('Server is currently busy, please try again later.', 'Dismiss', {
            duration: 2000,
          });
        }
      },
    });
  }

  appendPixelBaseCode(analyticsId) {
    try {
      if (this.globals.isNullOrEmpty(analyticsId)) {
        return;
      }

      const script = document.createElement('script');
      script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('track', 'PageView');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending facebook pixel code');
      console.error(ex);
    }
  }

  checkSiteUrl(): void {
    if (environment.production === true) {
      let currentUrl = window.location.href.toLowerCase();
      if (!currentUrl.startsWith(environment.baseUrl)) {
        currentUrl = environment.baseUrl + new URL(currentUrl).pathname;
        window.location.href = currentUrl;
      }
    }
  }
}
