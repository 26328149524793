<mat-card class="mat-card">
  <div *ngIf="globals.Store.Customer.Cart" class="cart-order-summary">
    <h5 class="title-text">Order Summary</h5>
    <div class="summary-subtotal row" style="margin: 0">
      <span class="col">Subtotal:</span>
      <span class="col text-right"> {{ globals.getCartTotalAmount() | number: '1.2-2' }}</span>
    </div>
    <!--div style="margin: 0" class="row">
			<span class="col">Total Weight (g):</span>
			<span class="col text-right"> {{getTotalWeight() | number:'1.2-2' }}</span>
		</div-->
    <div style="margin: 0" class="row">
      <span class="col">Shipping Fee:</span>
      <span class="col text-right"> {{ globals.Store.Customer.Cart.ShippingFee | number: '1.2-2' }}</span>
    </div>

    <div style="border-top: 3px solid #e3e3e3;" class="row m-0 mb-2" *ngIf="globals.Store.Customer.Cart.PaymentMethod == 1 || globals.Store.Customer.Cart.PaymentMethod == 4">
      <span class="col txt-size-small">
        <span class="font-weight-bold txt-black">Total</span>
        <span class="txt-grey">:</span>
      </span>
      <span class="col text-right">
        {{ globals.Store.Currency }} {{ globals.getCartTotalAmount() + globals.Store.Customer.Cart.ShippingFee | number: '1.2-2' }}
      </span>
    </div>

    <div style="margin: 0" class="row" *ngIf="globals.Store.Customer.Cart.PaymentMethod == 1 || globals.Store.Customer.Cart.PaymentMethod == 4">
      <span class="col">Other Charges:</span>
      <span class="col text-right"> {{ globals.getTotalAdditionalCharges() | number: '1.2-2' }}</span>
    </div>

    <div style="border-top: 3px solid #e3e3e3; padding-top: 10px" class="row m-0">
      <span class="col txt-size-small">
        <span class="font-weight-bold txt-black">Grand Total</span>
        <span class="txt-grey">:</span>
      </span>
      <strong class="col text-right txt-large txt-bold price">
        {{ globals.Store.Currency }} {{ globals.getGrandTotal() | number: '1.2-2' }}
      </strong>
    </div>
  </div>
</mat-card>
