import { CartItem } from './cart-item';
import { Address } from './address';
import { AdditionalCharge } from './additional-charge';

export class Cart {
  Id: string;
  MemberId: string;
  Items: CartItem[] = [];
  GrandTotal?: number;
  TotalAmount: number;
  TotalDiscount: number;
  TotalPoints: number;
  ShippingFee = 0.0;
  PaymentMethod: number = 0;
  DeliveryMethod = 1;
  PickupLocation = 0;
  Delivery?: {
    Courier?: string;
    CourierId?: number;
    State?: string;
    StateId?: number;
    City?: string;
    CityId?: number;
  };
  OtherCharges?: Array<AdditionalCharge> = [];
  Billing: Address;
  Shipping: Address;
  UseSameAddress = true;
  VendorId: string;
}
